import _ from 'lodash'
import { assessments as config } from '../../data/config.coffee'
import CommonHelpers from './mixins/CommonHelpers.coffee'
import MmogRadioGroup from '../generic/MmogRadioGroup.vue'
import MmogTextarea from '../generic/MmogTextarea.vue'
import MmogDatepicker from '../generic/MmogDatepicker.vue'
import MmogSelect from '../generic/MmogSelect.vue'
import MmogCheckbox from '../generic/MmogCheckbox.vue'
import MmogInput from '../generic/MmogInput.vue'
import TwoButtonDialog from '../shared/TwoButtonDialog'
import moment from 'moment'
export default
	mixins: [CommonHelpers]
	props: originalAnswer: Object, assessment: Object, allAnswers: Boolean
	components: { MmogRadioGroup, MmogTextarea, MmogDatepicker, MmogSelect, MmogCheckbox, MmogInput, TwoButtonDialog }
	data: ->
		isDevelop: @$store.state.isDevelop
		answer: null
		canEditAssessor: false
		pending: false
		pendingDeletion: false
		complianceChoices: [
			{ value: 'yes', label: @$t('enumerize.answer.compliance.yes') }
			{ value: 'no', label: @$t('enumerize.answer.compliance.no') }
			{ value: 'NA', label: @$t('enumerize.answer.compliance.NA') }
		]
		approvalChoices: [
			{ value: 'true', label: @$t('simple_form.yes') }
			{ value: 'false', label: @$t('simple_form.no') }
		]
		spinnerLineFgColor: '#00725e'
		spinnerLineBgColor: '#c7dcd7'
		spinnerSize: 15
	watch:
		answer: -> @canEditAssessor = !!@answer.alternative_assessor or !!@answer.verifier
	computed:
		isEditable: -> @assessment.infos.status is 'in_progress' or @assessment.infos.status is 'in_review'
		canEditAnswers: -> @assessment.infos.answer_editability.edit_answers
		canEditFeedback: -> @assessment.infos.answer_editability.edit_feedback
		canViewFeedback: -> @assessment.infos.answer_editability.view_feedback
		canDestroyAnswers: -> @assessment.infos.answer_editability.destroy_answers
		isDirty: -> not _.isEqual(@answer, @originalAnswer)
		updatedAtFormatted: -> moment(@answer.updated_at).format('lll')

	methods:
		openSurveyHelp: ->
			window.open 'http://www.odette.org/mmoghelp/en/' + @$t('answers.form.help.survey')
		openReviewHelp: ->
			window.open 'http://www.odette.org/mmoghelp/en/' + @$t('answers.form.help.review')
		save: ->
			@pending = true
			try
				if @answer.id
					response = await @$http.put(config.answers + @answer.id, { answer: @answer })
					@answer = response.data.answer
				else
					response = await @$http.post(config.endpoint + @assessment.infos.id + '/criteria/' +
						@answer.criterion_id + '/answers', { answer: @answer })
					@answer = response.data.answer
					if not @assessment.answers_in_profile.some((answer) => answer.criterion_id is @answer.criterion_id)
						@assessment.answers_in_profile.push(@answer)
				@updateOriginalAnswer()
				@updateAssessmentInfos response.data
				if @answer?.valid
					@$emit 'toggle-answer'
					document.getElementById('criterion-' + @answer.criterion_id)?.scrollIntoView(true)
			catch error
				@updateAssessmentInfos error.response?.data
			@pending = false
		updateOriginalAnswer: ->
			@updateObject @originalAnswer, @answer
		updateAssessmentInfos: (response) ->
			if(response.answer)
				delete response.answer
			@updateObject @assessment.infos, response
		resetAnswer: ->
			@answer = _.cloneDeep(@originalAnswer)
		confirmDeleteAnswer: ->
			@$vfm.show component: TwoButtonDialog, bind:
				title: @$t('pages.confirm')
				subtitle: @$t('pages.cannot_undone')
				leftButtonText: @$t('pages.cancel')
				rightButtonText: @$t('pages.ok')
				callbackRightButton: => @deleteAnswer()
			, height: 'auto'
		deleteAnswer: ->
			@pendingDeletion = true
			try
				response = await @$http.delete(config.answers + @answer.id)
			catch error
				@updateAssessmentInfos error.response?.data
				@pendingDeletion = false
				return
			@updateAssessmentInfos response.data
			@$emit 'reset-answer'
	created: ->
		@answer = _.cloneDeep(@originalAnswer)
