import WorkflowToolbar from './WorkflowToolbar.vue'
import LookupFilters from './filters/LookupFilters.vue'
import ProgressWidget from './ProgressWidget.vue'
import WeightFilters from './filters/WeightFilters.vue'
import ComplianceFilters from './filters/ComplianceFilters.vue'
import HierarchyFilters from './filters/HierarchyFilters.vue'
import AnswerContentFilters from './filters/AnswerContentFilters.vue'
import ApprovalFilters from './filters/ApprovalFilters.vue'
import CriterionAppearanceFilters from './filters/CriterionAppearanceFilters.vue'
import {ListLoader} from 'vue-content-loader'
import AssessmentFilters from './filters/AssessmentFilters.vue'
import {assessments as config} from '../../data/config.coffee'
import ScoreDiff from './ScoreDiff.vue'
import CompareVisibilityFilters from './filters/CompareVisibilityFilters'
import CompareDetailTable from '../generic/CompareDetailTable'
import CompareHierarchyFilters from './filters/CompareHierarchyFilters'

export default
	components: {
		CompareVisibilityFilters, CompareHierarchyFilters,
		WorkflowToolbar, LookupFilters, ProgressWidget,
		WeightFilters, ComplianceFilters, HierarchyFilters, AnswerContentFilters,
		ApprovalFilters, CriterionAppearanceFilters, ListLoader, AssessmentFilters, ScoreDiff, CompareDetailTable
	}
	data: ->
		filterChoices: [
			{ value: 'all', label: @$t('assessments.sidebar.diff.all') }
			{ value: 'diff_only', label: @$t('assessments.sidebar.diff.diff_only') }
		]
		isCompareMode: @targetExists
		isResetCompare: @targetAssessment is null
	props:
		assessmentInfos: Object
		targetAssessment: Object
		filters: Object
		loading: Boolean
		metrics: Object
		targetExists: Boolean
	computed:
		perspective: ->
			if @assessmentInfos.partner.role is 'customer'
				'supplier'
			else
				'customer'
		pathToPartner: -> '/companies/' + @assessmentInfos.partner_id
		pathToSite: -> '/sites/' + @assessmentInfos.site_id
		isAnyTarget: -> @targetAssessment?.title?
		getBodySource: -> [
			{ title: @$t('activerecord.attributes.assessment.site.one'), value: @assessmentInfos.site.title },
			{ title: @$t('activerecord.attributes.assessment.catalog_profile')
				, value: "#{@assessmentInfos.profile.catalog.name}/#{@assessmentInfos.profile.title}" },
			{ title: @$t('activerecord.attributes.company.partner.one'), value: @assessmentInfos.partner.title },
			{ title: @$t('activerecord.attributes.assessment.status'), value: @getTranslatedStatus(@assessmentInfos.status) },
			{ title: @$t('activerecord.attributes.assessment.shipping_site'), value: @assessmentInfos.shipping_site }
			{ title: @$t('activerecord.attributes.assessment.manufacturing_site')
				, value: @assessmentInfos.manufacturing_site }
		]
		getBodyTarget: -> [
			{ title: @$t('activerecord.attributes.assessment.site.one'), value: @targetAssessment?.site?.title },
			{ title: @$t('activerecord.attributes.assessment.catalog_profile')
				, value: "#{@targetAssessment.profile.catalog.name}/#{@targetAssessment.profile.title}" },
			{ title: @$t('activerecord.attributes.company.partner.one'), value: @targetAssessment?.partner?.title },
			{ title: @$t('activerecord.attributes.assessment.status'), value: @getTranslatedStatus(@targetAssessment.status) },
			{ title: @$t('activerecord.attributes.assessment.shipping_site'), value: @targetAssessment.shipping_site }
			{ title: @$t('activerecord.attributes.assessment.manufacturing_site')
				, value: @targetAssessment.manufacturing_site }
		]

	methods:
		updateTarget: (target) ->
			@isResetCompare = false
			Object.assign(@targetAssessment, target)
		getTabState: (targetPresents) ->
			if targetPresents then 'is-active' else ''
		resetFilters: ->
			if @isCompareMode
				@isResetCompare = true
			@$emit 'reset-filters'
		setCompareFilter: (value) ->
			@$emit 'compare-filter-all', value

		compareAssessments: ->
			targetId = @targetAssessment.id
			if targetId > 0
				url = config.endpoint + targetId + '/answers'
				await @asyncGet url, (response) =>
					@targetAssessment.answers = response.data
					@$emit('update-target', @targetAssessment)

		asyncGet: (url, response) ->
			@$http.get(url)
				.then response
				.catch (error) -> @loadingError = error.response?.data?.flash

		enableCompareMode: (value) -> #todo: emit causes performance's lost
			@isCompareMode = value
			@$emit('compare-mode', value)

		getTranslatedStatus: (status) ->
			switch status.toLowerCase()
				when 'archived' then return @$t('activerecord.attributes.assessment.status/archived')
				when 'completed' then return @$t('activerecord.attributes.assessment.status/completed')
				when 'in_progress' then return @$t('activerecord.attributes.assessment.status/in_progress')
				when 'in_review' then return @$t('activerecord.attributes.assessment.status/in_review')
				when 'new' then return @$t('activerecord.attributes.assessment.status/new')
				when 'reviewed' then return @$t('activerecord.attributes.assessment.status/reviewed')
				else
					return 'undef'

