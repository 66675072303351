import Answer from './Answer.vue'
import RelevantAnswerAttribute from './RelevantAnswerAttribute.vue'
import TwoButtonDialog from '../shared/TwoButtonDialog.vue'
import _ from 'lodash'
import MmogDifferTable from '../generic/MmogDifferTable'

isBlank = (s) -> not s or s.length is 0

export default
	name: 'criterion'
	components: { Answer, RelevantAnswerAttribute, TwoButtonDialog, MmogDifferTable }
	props:
		assessment: Object
		targetAnswer: type: Object, default: () -> compliance: 'undef'
		criterion: Object
		filters: Object
		targetExists: Boolean
		isCompareMode: Boolean
	data: ->
		showAnswer: false
		answer:
			criterion_id: @criterion.id, compliance: 'undef', errors: {}
	computed:
		isRendered: ->
			if (not @isCompareMode)
				filters = [
					@filters.weights[@criterion.weight]
					@filters.compliances[@answer.compliance or 'undef'],
					@filterNaApproved, @filterNaUnapproved,
					@filterBusinessFunction, @filterResponsibility,
					@filterNaApprovedOnly, @filterNaUnapprovedOnly, @filterMissingTargetDate,
					@filterMissingCompletionDate, @filterMissingActionPlan, @filterWithActionPlan, @filterInvalidResponses,
					@filterRejectedResponses, @filterResponsesWithCustomerFeedback, @filterResponsesWithoutCustomerFeedback
				]
			else
				filters = [@filterCompare]
			return _.every(filters)
		isShown: ->
			if @isCompareMode
				not @filters.compareHierarchy.requirementsOnly
			else
				not @filters.hierarchies.requirementsOnly
		toggleAnswerClass: ->
			if(@assessment.answers_in_profile)
				switch @answer.compliance
					when 'yes' then return 'success fi-check'
					when 'no' then return 'alert fi-x'
					when 'NA' then return 'warning fi-prohibited'
					else
						return 'primary fi-pencil'
			else
				return 'loader'

		toggleCompareClass: ->
			if @targetAnswer
				return if @isIdentical then 'success fi-page-multiple' else 'warning fi-page-multiple'
			else if @answer.valid
				return 'loader'
			else
				return 'warning fi-page-multiple'

		toggleAnswerTitle: () ->
			if @assessment.answers_in_profile
				switch @answer.compliance
					when 'yes' then return @$t('assessments.sidebar.hint.fully_compliant')
					when 'no' then return @$t('assessments.sidebar.hint.not_compliant')
					when 'NA' then return @$t('assessments.sidebar.hint.not_applicable')
					else
						return @$t('assessments.sidebar.hint.unanswered')
			else
				return 'loading...'
		naApproved: ->
			@answer.compliance is 'NA' and not isBlank(@answer.approval_date) and not isBlank(@answer.customer_contact)
		naUnapproved: ->
			@answer.compliance is 'NA' and
			@answer.approved is null and
			(isBlank(@answer.approval_date) or isBlank(@answer.customer_contact))
		filterNaApproved: ->
			if not @filters.approvals.approved
				not @naApproved
			else
				true
		filterNaUnapproved: ->
			if not @filters.approvals.unapproved
				not @naUnapproved
			else
				true
		filterBusinessFunction: ->
			@filters.lookups.businessFunction is '' or @filters.lookups.businessFunction is @answer.business_function
		filterResponsibility: ->
			@filters.lookups.responsibility is '' or @filters.lookups.responsibility is @answer.responsibility
		filterNaApprovedOnly: ->
			not @filters.answerContents.naApproved or @naApproved
		filterNaUnapprovedOnly: ->
			not @filters.answerContents.naUnapproved or @naUnapproved
		filterMissingTargetDate: ->
			today = new Date()
			today.setHours(0, 0, 0, 0)
			targetDate = Date.parse(@answer.target_date)
			not @filters.answerContents.targetDate or (
				@answer.compliance is 'no' and (isBlank(@answer.target_date) or targetDate <= today)
			)
		filterMissingCompletionDate: ->
			not @filters.answerContents.completionDate or (@answer.compliance is 'yes' and isBlank(@answer.completion_date))
		filterMissingActionPlan: ->
			not @filters.answerContents.actionPlan or (@answer.compliance is 'no' and isBlank(@answer.action_plan))
		filterWithActionPlan: ->
			not @filters.answerContents.withActionPlan or not isBlank(@answer.action_plan)
		filterInvalidResponses: ->
			not @filters.answerContents.incomplete or @answer.valid isnt true
		filterRejectedResponses: ->
			not @filters.answerContents.rejected or @answer.approved is false
		filterResponsesWithCustomerFeedback: ->
			not @filters.answerContents.withFeedback or (not isBlank(@answer.approved) or not isBlank(@answer.customer_response))
		filterResponsesWithoutCustomerFeedback: ->
			not @filters.answerContents.withoutFeedback or (isBlank(@answer.approved) and isBlank(@answer.customer_response))

		criterionClasses: ->
			style = 'criterion-response-' + if @answer.valid then 'valid' else 'invalid'
			if @filters.appearances.colorize
				switch @answer.compliance
					when 'yes' then style += ' compliant'
					when 'no' then style += ' not-compliant'
					when 'NA' then style += ' compliance-na'
					else
						style += ' compliance-undef'
			style
		criterionWeightClass: ->
			'F' + @criterion.weight
		filterCompare: ->
			if @filters.compareVisibility.all
				true
			else if not @isIdentical
				true
			else false
		isIdentical: ->
			if @targetAnswer.compliance is @answer.compliance
				return true
			else
				return false
	watch:
		isRendered: (val) ->
			@$emit('visibility-change', val)
		'assessment.answers_in_profile': (answers) ->
			for answer in answers
				if answer.criterion_id is @criterion.id
					@answer = answer
					break
			return
	methods:
		toggleAnswer: (answerId) ->
			if not $('#form' + answerId).hasClass('dirty')
				@toggleAnswerVisibility()
			else
				@$vfm.show component: TwoButtonDialog, bind:
					title: @$t('activerecord.attributes.answer.dialog.title')
					subtitle: @$t('activerecord.attributes.answer.dialog.subtitle')
					leftButtonText: @$t('activerecord.attributes.answer.dialog.dont_save')
					rightButtonText: @$t('activerecord.attributes.answer.dialog.save')
					callback: => @toggleAnswerVisibility()
					callbackRightButton: => @$refs.answer.save()
			return

		toggleAnswerVisibility: ->
			@$el.scrollIntoView(true) if @showAnswer = not @showAnswer
			return

		resetAnswer: () ->
			@deleteAnswerFromAssessment()
			@answer =
				criterion_id: @criterion.id, compliance: 'undef', errors: {}
			@toggleAnswerVisibility()

		deleteAnswerFromAssessment: () ->
			for answer, i in @assessment.answers_in_profile
				if answer.criterion_id is @criterion.id
					@assessment.answers_in_profile.splice(i, 1)
					return
